import React, { useState, useEffect } from "react";

import { useCore } from "../Core";

export default function Header() {
	
    const { 
        authUser,
        isTeacher, 
        globalRoomUsers,
        setOpenActiveAttachment, 
        activeAttachments, 
        currentLesson,
        currentUserGroup,
        currentQuiz,
        quizDone,
        quizClose,
        sendLogout
    } = useCore();

    const [ activeTeacher, setActiveTeacher ] = useState( null );
    const [ numGlobal, setNumGlobal ] = useState( 0 );
    const [ numActive, setNumActive ] = useState( 0 );
    const [ numInGroup, setNumInGroup ] = useState( 0 );
    const [ numQuizDone, setNumQuizDone ] = useState( 0 );

    /* On Update */

    useEffect( () => {

        const global = Object.keys( globalRoomUsers ).filter( ( k ) => { 
            return ( !globalRoomUsers[ k ].isTeacher );
        } ).length;

        setNumGlobal( global );
        
        const active = Object.keys( globalRoomUsers ).filter( ( k ) => { 
            return ( !globalRoomUsers[ k ].isTeacher && globalRoomUsers[ k ].isActive === true );
        } ).length;

        setNumActive( active );

        const quizdone = Object.keys( globalRoomUsers ).filter( ( k ) => { 
            return ( !globalRoomUsers[ k ].isTeacher && globalRoomUsers[ k ].hasQuizDone === true );
        } ).length;

        setNumQuizDone( quizdone );

        // TODO -- per evitare problema di utenti con stesso id qui bisogna escludere dall'elenco quelli che hanno il mio stesso name

        const ingroup = Object.keys( globalRoomUsers ).filter( ( k ) => { 
            //return ( !globalRoomUsers[ k ].isTeacher && globalRoomUsers[ k ].room === currentUserGroup );
            return ( !globalRoomUsers[ k ].isTeacher && globalRoomUsers[ k ].name !== authUser.name && globalRoomUsers[ k ].room === currentUserGroup );
        } ).length;

        setNumInGroup( ingroup + 1 );

        let teacher = null;

        for ( const [ k, user ] of Object.entries( globalRoomUsers ) ) {

			if ( user.isTeacher ) teacher = user;

		}

        console.log('**** teacher', teacher);

        setActiveTeacher( teacher );
    
    }, [ globalRoomUsers, currentUserGroup ] );
    
    /* */

    const closeQuizTeacher = () => {

        let r = window.confirm( 'Terminare il quiz?' );

		if ( r ) {

            console.log( 'Close Quiz Teacher...' );

            quizClose();

        }

    };

    /* */

    const closeQuizStudent = () => {

        let r = window.confirm( 'Consegnare il quiz?' );

		if ( r ) {

            console.log( 'Close Quiz Student...' );

            quizDone();

        }

    };

    /* */
    
	return (	

        currentQuiz ?
		
            <header className="header header-quiz d-flex align-items-center">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="dash-header d-flex align-items-center">
                                <div className="dash-icon h-100">
                                    <i className="fas fa-list"></i>
                                </div>
                                <div className="dash-title ml-4">
                                    <div className="dash-title-name">
                                        <h3>{ currentQuiz.quiz.name }</h3>
                                    </div>

                                    {
                                        isTeacher ?

                                            <div className="dash-title-info mt-2">
                                                <span className="class">
                                                    CLASSE
                                                </span>
                                                <div className="badge">
                                                    <span className="num">{ numGlobal }</span> {" "}
                                                    <span className="desc">studenti</span>
                                                </div>
                                                <div className="badge available">
                                                    <span className="num">{ numActive }</span> {" "}
                                                    <span className="desc">attivi</span>
                                                </div>
                                                <div className="badge unavailable">
                                                    <span className="num">{ numGlobal - numActive }</span> {" "}
                                                    <span className="desc">non attivi</span>
                                                </div>
                                            </div>

                                        :

                                            <div className="dash-title-info mt-2">
                                                {
                                                    authUser.hasQuizDone ?
                                                        <button className="btn disabled">CONSEGNATO</button>
                                                    :
                                                        <button className="btn" onClick={ () => closeQuizStudent() }>CONSEGNA</button>
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="h-100 d-flex align-items-center justify-content-end">
                                
                                {
                                    isTeacher ?
                                        <>
                                            <div className="quiz-status">
                                                <div className="badge available">
                                                    <span className="num">{ numQuizDone }</span> {" "}
                                                    <span className="desc">CONSEGNATO</span>
                                                </div>
                                                <div className="badge idle">
                                                    <span className="num">{ numGlobal - numQuizDone }</span> {" "}
                                                    <span className="desc">IN CORSO</span>
                                                </div>
                                            </div>

                                            <div className="quiz-timer">
                                                <h4>{ currentQuiz.duration }</h4>
                                                <button className="btn btn-block btn-danger" onClick={ () => closeQuizTeacher() }>TERMINA QUIZ</button>
                                            </div>
                                        </>
                                    :
                                        <div class="quiz-status">
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <div class="list-text">
                                                        <i class="far fa-question-circle"></i> {" "}
                                                        Domande totali:
                                                    </div>
                                                    <div class="list-value">
                                                       { currentQuiz.exercises ? currentQuiz.exercises.length : '0' }
                                                    </div>
                                                </li>
                                                <li class="list-group-item d-flex justify-content-between">
                                                    <div class="list-text">
                                                        <i class="far fa-stopwatch"></i> {" "}
                                                        Durata Massima:
                                                    </div>
                                                    <div class="list-value">
                                                        { currentQuiz.duration }
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </header>

        :
                 
            <header className="header d-flex align-items-center">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="dash-header d-flex align-items-center">
                                <div className="dash-icon h-100">
                                    <i className={ currentUserGroup === "0" ? "fas fa-users" : "fas fa-th-large" }></i>
                                </div>
                                <div className="dash-title ml-4">
                                    <div className="dash-title-name">
                                        <h3>{ currentLesson ? currentLesson.titolo : 'Nessuna lezione caricata' }</h3>
                                    </div>
                                    <div className="dash-title-info mt-2">
                                        <span className="class">
                                            { currentUserGroup === "0" ? "CLASSE" : "GRUPPO " + currentUserGroup }
                                        </span>                                    
                                        
                                        {
                                            isTeacher ?
                                            
                                                <>
                                                    <div className="badge">
                                                        <span className="num">{ numGlobal }</span> {" "}
                                                        <span className="desc">studenti</span>
                                                    </div>
                                                    <div className="badge available">
                                                        <span className="num">{ numActive }</span> {" "}
                                                        <span className="desc">attivi</span>
                                                    </div>
                                                    <div className="badge unavailable">
                                                        <span className="num">{ numGlobal - numActive }</span> {" "}
                                                        <span className="desc">non attivi</span>
                                                    </div>
                                                </>
                                            
                                            :
                                                <div className="badge">
                                                    <span className="num">{ numInGroup }</span> {" "}
                                                    <span className="desc">studenti</span>
                                                </div>
                                            
                                        }
                                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="dash-rule h-100 d-flex align-items-end justify-content-end">

                            {
                                isTeacher ?
                                    <>  
                                        <div className="mr-4">
                                            <div className={ activeAttachments && activeAttachments[ "0" ] ? "badge active" : "badge" } onClick={ activeAttachments && activeAttachments[ "0" ] ? () => setOpenActiveAttachment( "0" ) : null }>
                                                <span>
                                                    <span className="num">
                                                        <i className="fab fa-telegram-plane"></i>
                                                    </span> 
                                                    {" "}
                                                    <span className="desc">comanda</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="card card-user d-flex flex-row justify-content-between align-items-center py-2 px-2" style={{ paddingRight: "20px !important" }}>
                                            <div className="user-icon-name d-flex align-items-center">
                                                <span className="user-name ml-2">
                                                    <small>Docente:</small> { authUser.name }<br/>
                                                    <small>Sessione:</small> { authUser.session_id }<br/>
                                                    <small><small>{ authUser.created_at }</small></small>
                                                </span>
                                            </div>
                                        </div>
                                    </>

                                :

                                    (
                                        activeTeacher ?
                                            <>
                                                <div className={ authUser.isTalking ? "card card-user active w-100 d-flex flex-row justify-content-between align-items-center py-2 px-2 mr-4" : "card card-user w-100 d-flex flex-row justify-content-between align-items-center py-2 px-2 mr-4" }>
                                                    <div className="user-icon-name d-flex align-items-center">
                                                        <span className="user-name ml-2">
                                                            <small>Studente:</small><br/>
                                                            { authUser.name }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className={ activeTeacher.isTalking && activeTeacher.room === currentUserGroup ? "card card-user active w-100 d-flex flex-row justify-content-between align-items-center py-2 px-2" : "card card-user w-100 d-flex flex-row justify-content-between align-items-center py-2 px-2" } style={{ paddingRight: "20px !important" }}>
                                                    <div className="user-icon-name d-flex align-items-center">
                                                        <span className="user-icon">
                                                            <i className="fas fa-user-tie"></i>
                                                        </span>
                                                        <span className="user-name ml-2">
                                                            <small>Docente:</small> { activeTeacher.name }<br/>
                                                            <small>Sessione:</small> { authUser.session_id }
                                                        </span>
                                                    </div>
                                                    <div className="user-status ml-1">
                                                        {
                                                            activeTeacher.isActive ?
                                                                <span className="available"></span>
                                                            :
                                                                <span className="unavailable"></span>
                                                        }                                        
                                                    </div>
                                                </div>
                                            </>

                                        :
                                                        
                                            <>
                                                <div className={ authUser.isTalking ? "card card-user active w-100 d-flex flex-row justify-content-between align-items-center py-2 px-2 mr-4" : "card card-user w-100 d-flex flex-row justify-content-between align-items-center py-2 px-2 mr-4" }>
                                                    <div className="user-icon-name d-flex align-items-center">
                                                        <span className="user-name ml-2">
                                                            <small>Studente:</small><br/>
                                                            { authUser.name }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="card card-user w-100 d-flex flex-row justify-content-between align-items-center py-2 px-2" style={{ paddingRight: "20px !important" }}>
                                                    <div className="user-icon-name d-flex align-items-center">
                                                        <span className="user-icon">
                                                            <i className="fas fa-user-tie"></i>
                                                        </span>
                                                        <span className="user-name ml-2">
                                                            <small>Docente:</small> <span className="text-danger">--</span> <br/>
                                                            <small>Sessione:</small> { authUser.session_id }
                                                        </span>
                                                    </div>
                                                    <div className="user-status ml-1">
                                                        <span className="unavailable"></span>
                                                    </div>
                                                </div>
                                            </>

                                    )
                            }

                            </div>
                        </div>
                    </div>
                </div>
            </header>
            
	);
  
}
