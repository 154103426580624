import React, { useState, useEffect } from "react";

import { 
	useCore, 
	apiRequest 
} from "../Core";

import config from "../config";

export default function Sidebar() {
	
	const { 
      setIsLoading,
      authUser, 
      sendLogout,
      isTeacher, 
      openAttachment, 
      activeAttachments, 
      openActiveAttachment,
      setOpenActiveAttachment, 
      setCurrentError, 
      currentLesson, 
      setCurrentLesson ,
      currentLessonAttachments,
		setCurrentLessonAttachments,
      currentUserGroup,
      currentGroupDetails,
      playMute,
      openSideBar,
      setOpenSideBar,
      openCloud,
      setOpenCloud,
      openSideAttachment,
      setOpenSideAttachment,
      isRecording,
		setIsRecording,
      activeRecordings,
      setOpenActiveRecordings,
      currentQuiz,
      setCurrentQuiz
   } = useCore();

   const [ coursesLoaded, setCoursesLoaded ] = useState( false );
   const [ listCourses, setListCourses ] = useState( [] );
   const [ listLessons, setListLessons ] = useState( null );
   const [ selectedCourseTitle, setSelectedCourseTitle ] = useState( '' );

   //const [ quizLoaded, setQuizLoaded ] = useState( false );
   const [ openQuizList, setOpenQuizList ] = useState( false );
   const [ listQuiz, setListQuiz ] = useState( [] );
   const [ selectedQuiz, setSelectedQuiz ] = useState( null );
   
   const [ isTalking, setIsTalking ] = useState( false );
   //const [ isTalkingAll, setIsTalkingAll ] = useState( false );

   /* On Update */

   useEffect( () => {

		if ( selectedQuiz ) {

         let r = window.confirm( 'Iniziare il quiz?' );

		   if ( r ) sendLoadExercices( selectedQuiz.id );

      }

	}, [ selectedQuiz ] );
   
   /* */

	const tglSideBar = () => {
	
		setOpenSideBar( openSideBar ? false : true );
		
      if ( openCloud ) setOpenCloud( false );
      if ( openQuizList ) setOpenQuizList( false );
      if ( openSideAttachment ) setOpenSideAttachment( false );
		
   };
   
   /* */

   const tglAttachment = () => {
	
		setOpenSideAttachment( openSideAttachment ? false : true );
		
      if ( openSideBar ) setOpenSideBar( false );
      if ( openCloud ) setOpenCloud( false );
      if ( openQuizList ) setOpenQuizList( false );
		
	};

   /* */
	
   const tglOpenCloud = () => {

      if ( openQuizList ) setOpenQuizList( false );

      setOpenCloud( openCloud ? false : true );

      if ( !coursesLoaded ) sendLoadCourses();

   };

   /* */

   async function sendLoadCourses() {
		
		try {

         setIsLoading( true );
			
			const endpoint = config.API_BASENAME + '/corsi/';
			const apiData = await apiRequest( endpoint, 'GET', authUser.tokenDjango );
			
			console.log( 'apiData:', apiData );
			
         setIsLoading( false );

			if ( apiData.result === "error" ) {
				
				setCurrentError( 'Errore durante il caricamento dei corsi.' );
				
			}
			else { 

            setCoursesLoaded( true );

            setListCourses( apiData );
				
			}
			
		} catch( err ) {
			
			console.log( 'Error:', err );

			setCurrentError( 'Error: ' + err );

         setIsLoading( false );
		
		}
		
	};

   /* */
	
   const openLessons = ( course, lessons ) => {

      setSelectedCourseTitle( course );
      setListLessons( lessons );

   };

   /* */
	
   const closeLessonList = () => {

      setSelectedCourseTitle( '' );
      setListLessons( null );

   };

   /* */

   async function sendLoadAttachments( lesson ) {
      
		try {

         setIsLoading( true );
			
			const endpoint = config.API_BASENAME + '/allegati/' + lesson.id + '/';
			const apiData = await apiRequest( endpoint, 'GET', authUser.tokenDjango );
			
			console.log( 'apiData:', apiData );

         setIsLoading( false );
			
			if ( apiData.result === "error" ) {
				
				setCurrentError( 'Errore durante il caricamento degli allegati.' );
				
			}
			else { 

            setCurrentLesson( lesson );
            setCurrentLessonAttachments( apiData );

            tglSideBar();
				
			}
			
		} catch( err ) {
			
			console.log( 'Error:', err );

			setCurrentError( 'Error: ' + err );

         setIsLoading( false );
		
		}

	};

   /* */

   function renderAttachmentIcon( k, item ) {

      switch( k ) {

         case 'doc':
            return <i className="fas fa-file"></i>;

         case 'audio':
            return <i className="fas fa-volume-up"></i>;

         case 'link':
            return <i className="fas fa-link"></i>;

         case 'video':
            return <i className="fas fa-video"></i>;  

         case 'img':
            return <figure className="mb-0"><img src={ item.preview_url } className="img-fluid"/></figure>;

         default:
            return <i className="fas fa-file"></i>;
      }

   };

   /* */

   const tglQuizList = () => { 

      if ( openCloud ) setOpenCloud( false );

      setOpenQuizList( openQuizList ? false : true );

      if ( !openQuizList ) sendLoadQuiz();
      
   };

   /* */

   async function sendLoadQuiz() {
		
		try {

         setIsLoading( true );

         const params = {
            token_cloud: authUser.cloudData.response.token
         };
			
			const endpoint = config.API_BASENAME + '/quiz/' + currentLesson.id + '/';
			const apiData = await apiRequest( endpoint, 'POST', authUser.tokenDjango, params );

         /*
         // TEST
			//const endpoint = 'https://api.npoint.io/f214760fd76a5c71621d';
         const endpoint = 'https://api.npoint.io/5d9bf41ba07e6672c857';
			const apiData = await apiRequest( endpoint, 'GET' );
         */

			console.log( 'apiData:', apiData );
			
         setIsLoading( false );

			if ( apiData.result === "error" ) {
				
				setCurrentError( 'Errore durante il caricamento dei quiz.' );
				
			}
			else { 

            //setQuizLoaded( true );

            setListQuiz( apiData.quizzes );
				
			}
			
		} catch( err ) {
			
			console.log( 'Error:', err );

			setCurrentError( 'Error: ' + err );

         setIsLoading( false );
		
		}
		
	};

   /* */

   async function sendLoadExercices( quizID ) {

      console.log( 'quizID:', quizID );
      
		try {

         setIsLoading( true );

         const params = {
            token_cloud: authUser.cloudData.response.token
         };
			
        const endpoint = config.API_BASENAME + '/quiz/' + quizID + '/details/';
		const apiData = await apiRequest( endpoint, 'POST', authUser.tokenDjango, params );

         /*
         // TEST
			const endpoint = ( quizID == "1") ? 'https://api.npoint.io/d9e1ea1e1f0b326ee7ae' : 'https://api.npoint.io/87d5ee247aa5801fc25a';
			const apiData = await apiRequest( endpoint, 'GET' );
			*/

         /*
         const endpoint = 'https://api.npoint.io/87d5ee247aa5801fc25a';
			const apiData = await apiRequest( endpoint, 'GET' );
         */
        
			console.log( 'apiData:', apiData );

            
			
			if ( apiData.result === "error" ) {
				
				setCurrentError( 'Errore durante il caricamento degli esercizi.' );
				
			}
			else { 

                tglSideBar();
                
                /**
                 * TODO inserire nuova chiamata API per ottenere ID sessione di quiz da inserire nell'oggetto dati di setCurrentQuiz
                 */
                const session_endpoint = config.API_BASENAME + '/quiz/' + quizID + '/session/';
                const session_data = await apiRequest( session_endpoint, 'POST', authUser.tokenDjango, params );

                console.log('session_data', session_data);

                setIsLoading( false );

                if ( session_data.result === "error" ) {
                    setCurrentError( 'Errore durante il recupero della session_id' );
                }else{
                    setCurrentQuiz( {
                        quiz: selectedQuiz,
                        session_id: session_data.session_id,
                        duration: apiData.duration,
                        isRunning: true,
                        exercises: apiData.exercises
                    } );
                }
			}
			
		} catch( err ) {
			
			console.log( 'Error:', err );

			setCurrentError( 'Error: ' + err );

         setIsLoading( false );
		
		}

	};

   /* */

   const sendTalk = () => {
		
      console.log( 'Talk...' );
      
      setIsTalking( true );

      playMute( true );
		
   };
   
   /* */

   const sendMute = () => {
		
      console.log( 'Mute...' );
      
      setIsTalking( false );
		
      playMute( false );

   };

   /* */

   const tglRec = () => {

      if ( activeAttachments && activeAttachments[ currentUserGroup ] ) {

         let recList = [ ...isRecording ];

         if ( recList.length ) {

            if ( recList[ currentUserGroup ] ) recList[ currentUserGroup ] = false;
            else recList[ currentUserGroup ] = true;

         }
         else {

            recList[ currentUserGroup ] = true;

         }

         console.log( 'recList:', recList );

         setIsRecording( recList );

      }

   };

   /* */

   function renderRecClasses() {

      let recClasses = "togglr togglr-rec mx-auto my-3 disabled";

      if ( activeAttachments && activeAttachments[ currentUserGroup ] ) {

         if ( isRecording[ currentUserGroup ] ) recClasses = "togglr togglr-rec mx-auto my-3 active";
         else recClasses = "togglr togglr-rec mx-auto my-3";

      }

      return recClasses;

   };

   /* */

   function closeSession() {

      let msg = 'Attenzione! Si è sicuri di voler uscire dalla classe?';

      if ( authUser.isTeacher ) {

         msg = 'Attenzione! Si è sicuri di voler chiudere la sessione?';
         
         if ( activeRecordings ) msg = 'Attenzione! Si è sicuri di voler chiudere la sessione? Eventuali registrazioni non salvate andranno perse.';

      }

      let r = window.confirm( msg );

		if ( r ) sendLogout();

   };

   /* */

	return (		

      currentQuiz ?

         null
      
      :
        
         <aside className={ "dash-sidebar d-flex flex-row flex-nowrap" + ( openSideBar ? " open-panel level-1" : "" ) + " " + ( openCloud ? "level-2" : "" ) + " " + ( openSideAttachment ? "open-panel level-3" : "" ) + " " + ( openQuizList ? "level-4" : "" ) }>
            
            <section className="dash-sidebar__main-toolbar d-flex flex-column justify-content-between p-4 text-center flex-grow-1">
               <div className="menu">
                  {
                     isTeacher ?

                        <span className={ currentGroupDetails || openActiveAttachment ? "menu-togglr disabled" : "menu-togglr"} onClick={ () => !currentGroupDetails && !openActiveAttachment ? tglSideBar() : null }>
                           <img src="assets/images/menu_togglr_icon.png" alt="Opedia Cloud"/>
                           <i className="fas fa-align-justify"></i>
                        </span>

                   :
                        <span className="menu-togglr" onClick={ () => closeSession() }>
                           <img src="assets/images/menu_togglr_icon.png" alt="Opedia Cloud"/>
                           <i className="fas fa-sign-out"></i>
                        </span>
                  }
               </div>
               <div className="voice-commands d-flex flex-column align-self-center justify-content-center">
                   
                  {
                     isTeacher ?

                        currentGroupDetails || openActiveAttachment ?

                           <button type="button" className="togglr togglr-attach mx-auto my-3 disabled">
                              <i className="fas fa-paperclip"></i>
                           </button>

                        :

                           <button type="button" className={ openSideAttachment ? "togglr togglr-attach mx-auto my-3 active" : "togglr togglr-attach mx-auto my-3" } onClick={ () => tglAttachment() }>
                              <i className="fas fa-paperclip"></i>
                           </button>

                     :
                        <button type="button" className={ activeAttachments && activeAttachments[ currentUserGroup ] ? "togglr togglr-attach active mx-auto my-3" : "togglr togglr-attach mx-auto my-3" } onClick={ activeAttachments && activeAttachments[ currentUserGroup ] ? () => setOpenActiveAttachment( currentUserGroup ) : null }>
                           <i className="fab fa-telegram-plane"></i>
                        </button>
                  }

                   <button type="button" className={ isTalking ? "togglr hero mx-auto my-3 active" : "togglr hero mx-auto my-3" } 
		                	onMouseDown={ () => sendTalk() } 
		                	onTouchStart={ () => sendTalk() } 
		                	onMouseUp={ () => sendMute() } 
		                	onMouseLeave={ () => ( isTalking ? sendMute() : null ) }
		                	onTouchEnd={ () => sendMute() }>
		                    <i className="fas fa-volume-up"></i>
		             </button>

                   {
                     isTeacher ?

                        <button type="button" className={ renderRecClasses() } onClick={ () => tglRec() }>
                              <i className="fas fa-circle"></i>
                              <span className="label">
                                 REC
                              </span>
                        </button>

                     :
                        null
                  }

               </div>

               <div className="group-commands">
               
                  {/*
                     isTeacher ?

                        <button type="button" className={ isTalkingAll ? "togglr togglr-group active" : "togglr togglr-group" }
                           onMouseDown={ () => sendTalkAll() } 
                           onTouchStart={ () => sendTalkAll() } 
                           onMouseUp={ () => sendMuteAll() } 
                           onMouseLeave={ () => ( isTalkingAll ? sendMuteAll() : null ) }
                           onTouchEnd={ () => sendMuteAll() }>
                              <i className="fas fa-volume-up"></i>
                              <i className="fas fa-users"></i>
                        </button>

                     :
                        null
                  */}

                  {/*
                     isTeacher ?

                        <button type="button" className="togglr togglr-group disabled">
                           <i className="fas fa-volume-up"></i>
                           <i className="fas fa-users"></i>
                        </button>

                     :
                        null
                  */}

               </div>

            </section>
            
            {
               isTeacher ?

                  <>

                     {/* Panel Nav */}
                     <section className="dash-sidebar__panel level-1 d-flex flex-column">
                        <div className="dash-sidebar__panel-wrapper px-5 pt-5 d-flex flex-column flex-grow-1">
                           <header>
                              <figure className="dash-sidebar__panel-logo">
                                 <img src="assets/images/panel-logo.png" className="img-fluid d-block mx-auto" alt="Opedia Cloud"/>
                              </figure>
                              <figure className="dash-sidebar__panel-cloud mb-0">
                                 <img src="assets/images/cloud.png" className="img-fluid d-block mx-auto" alt="Opedia Cloud"/>
                              </figure>
                           </header>
                           <nav className="dash-sidebar__panel-nav">
                              <ul>
                                 <li onClick={ () => tglOpenCloud() } className={ openCloud ? "active" : "" } >
                                    <label className="position-relative d-flex flex-row flex-nowrap">
                                       <div className="icon"><i className="far fa-book-open"></i></div>
                                       <p className="label__title mb-0">APRI LEZIONE</p>
                                       <p className="label__subtitle mb-0">{ currentLesson ? currentLesson.titolo : 'Nessuna lezione caricata' }</p>
                                    </label>
                                 </li>
                                {
                                   currentLesson ?
                                 
                                       <li onClick={ () => tglQuizList() } className={ ( openQuizList ? "active" : "" ) }>
                                          <label className="position-relative d-flex flex-row flex-nowrap">
                                             <div className="icon"><i className="far fa-list"></i></div>
                                             <p className="label__title mb-0">INIZIA UN QUIZ</p>
                                          </label>
                                       </li>

                                    :

                                       <li className="disabled">
                                          <label className="position-relative d-flex flex-row flex-nowrap">
                                             <div className="icon"><i className="far fa-list"></i></div>
                                             <p className="label__title mb-0">INIZIA UN QUIZ</p>
                                          </label>
                                       </li>
                                }
                                 
                              </ul>
                           </nav>
                           <footer className="mt-auto">
                              <div className="roundbox profesor-actions">
                                 <div className="roundbox__wrapper text-center pt-4 px-4 pb-4 pt-xxl-4 px-xxl-5 pb-xxl-5 mt-5">
                                    <div className="profesor-info">
                                       <div className="icon"><i className="fas fa-user-tie"></i></div>
                                       <p className="mb-0 profesor-fullname">{ authUser.name }</p>
                                    </div>
                                    <div className="actions mt-4">
                                       <button 
                                          className={ activeRecordings ? "btn basic text-uppercase btn-block save" : "btn basic text-uppercase btn-block save disabled" } 
                                          onClick={ activeRecordings ? () => setOpenActiveRecordings( true ) : null }>
                                          <span>Salva svolgimento</span>
                                       </button>
                                       <button className="btn basic text-uppercase btn-block close mt-3" onClick={ () => closeSession() }>
                                          <span>Chiudi sessione</span>
                                       </button>                                       
                                    </div>
                                    <div className="help mt-5">
                                       <p className="mt-1">
                                          <a href="/dashboard/" target="_blank" rel="noreferrer">
                                             <i className="fas fa-list"></i> <small><span className="ml-2">Accedi alla Dashboard</span></small>
                                          </a>
                                       </p>
                                       <p className="mt-1">
                                          <a href="https://www.eugenilab.it/" target="_blank" rel="noreferrer">
                                             <i className="fas fa-question-circle"></i> <small><span className="ml-2">Hai bisogno di aiuto?</span></small>
                                          </a>
                                       </p>
                                       <p className="mt-5"><small>Opedia Lingue Cloud - v { config.APP_VERSION }</small></p>
                                    </div>
                                 </div>
                              </div>
                           </footer>
                        </div>
                     </section>
                     
                     {/* Panel Lessons */}
                     <section className="dash-sidebar__panel level-2 d-flex flex-column">
                        <div className="dash-sidebar__panel-wrapper px-5 pt-5 d-flex flex-column flex-grow-1">
                           <header>
                              <figure className="dash-sidebar__panel-logo">
                                 <img src="assets/images/panel-logo-cloud.png" className="img-fluid d-block mx-auto" alt="Opedia Cloud"/>
                              </figure>
                              <figure className="dash-sidebar__panel-cloud mb-0">
                                 <img src="assets/images/cloud.png" className="img-fluid d-block mx-auto" alt="Opedia Cloud"/>
                              </figure>
                           </header>

                           {
                              listLessons ?

                                 <div className="dash-sidebar__panel-lessons flex-grow-1">
                                    <div className="dash-sidebar__panel-lessons-nav-title d-flex flex-row align-items-center px-2" onClick={ () => closeLessonList() }>
                                       <span className="mr-3"><i className="far fa-chevron-left"></i></span>
                                       <span className="ml-2">{ selectedCourseTitle }</span>
                                    </div>
                                    <nav className="dash-sidebar__panel-lessons-nav">
                                       <ul>
                                          {
                                             listLessons.map(( item ) => {
                                                return (

                                                   <li key={ item.id } onClick={ () => sendLoadAttachments( item ) }>
                                                      <div className="wrap d-flex flex-row justify-content-between align-items-center px-2">
                                                         <div className="label flex-grow-1">
                                                            <span><i className="fas fa-edit"></i></span>
                                                            <span className="ml-2">{ item.titolo }</span>
                                                         </div>
                                                      </div>
                                                   </li>

                                                )
                                             })
                                          }                                    
                                       </ul>
                                    </nav>
                                 </div>
                              :
                                 <div className="dash-sidebar__panel-lessons flex-grow-1">
                                    <div className="dash-sidebar__panel-lessons-nav-title d-flex flex-row align-items-center px-2">
                                       <span><i className="fas fa-cloud"></i></span>
                                       <span className="ml-2">Corsi disponibili</span>
                                    </div>
                                    <nav className="dash-sidebar__panel-lessons-nav">
                                       <ul>
                                          {
                                             listCourses.map(( item ) => {
                                                return (

                                                   <li key={ item.id } onClick={ () => openLessons( item.titolo, item.lezioni ) }>
                                                      <div className="wrap d-flex flex-row justify-content-between align-items-center px-2">
                                                         <div className="label flex-grow-1">
                                                            <span><i className="fas fa-edit"></i></span>
                                                            <span className="ml-2">{ item.titolo }</span>
                                                         </div>
                                                         <i className="far fa-chevron-right d-inline-block"></i>
                                                      </div>
                                                   </li>

                                                )
                                             })
                                          }                                    
                                       </ul>
                                    </nav>
                                 </div>
                           }

                        </div>
                     </section>
                     
                     {/* Panel Lesson Attachments */}
                     <section className="dash-sidebar__panel level-3 d-flex flex-column">
                        <div className="dash-sidebar__panel-wrapper px-5 pt-5 d-flex flex-column flex-grow-1">
                           <header>
                              <figure className="dash-sidebar__panel-logo">
                                 <img src="assets/images/panel-logo-cloud.png" className="img-fluid d-block mx-auto"/>
                              </figure>
                              <figure className="dash-sidebar__panel-cloud mb-0">
                                 <img src="assets/images/cloud.png" className="img-fluid d-block mx-auto"/>
                              </figure>
                           </header>
                           <div className="dash-sidebar__panel-materials flex-grow-1">
                              <div className="dash-sidebar__panel-lessons-nav-title d-flex flex-row align-items-center px-2">
                                 <span><i className="fas fa-edit"></i></span>
                                 <span className="ml-2">{ currentLesson ? currentLesson.titolo : 'Nessuna lezione caricata' }</span>
                              </div>
                              <nav className="dash-sidebar__panel-materials-nav">
                                 {
                                    currentLessonAttachments ?
                                       <ul>
                                          {
                                             Object.entries( currentLessonAttachments ).map( ( [ k, media ] ) => {
                                                return (

                                                   media.map(( item ) => {
                                                      return (

                                                         <li key={ item.media_id } className="active" onClick={ () => openAttachment( k, item ) }>
                                                            <div className="wrap">
                                                               <div className="label px-2 py-3">
                                                                  <span className="icon mr-2">
                                                                     { renderAttachmentIcon( k, item ) }
                                                                  </span>
                                                                  <div className="label-wrapper">
                                                                     <p className="material-name mb-2">{ item.name }</p>
                                                                     {/*<p className="mb-0 material-groups small">
                                                                        <span><i className="fas fa-paper-plane"></i></span>
                                                                        <span className="ml-2 text-uppercase">Classe, Gruppo 1, Gruppo 2</span>
                                                                     </p>*/}
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </li>

                                                      )
                                                   })
                                                )
                                             } )   
                                          }
                                         
                                       </ul>
                                    :
                                       null                                 
                                 }

                              </nav>
                           </div>
                        </div>
                     </section>

                     {/* Panel Quiz */}
                     <section className="dash-sidebar__panel level-4 d-flex flex-column">
                        <div className="dash-sidebar__panel-wrapper px-5 pt-5 d-flex flex-column flex-grow-1">
                           <header>
                              <figure className="dash-sidebar__panel-logo">
                                 <img src="assets/images/panel-logo-cloud.png" className="img-fluid d-block mx-auto"/>
                              </figure>
                              <figure className="dash-sidebar__panel-cloud mb-0">
                                 <img src="assets/images/cloud.png" className="img-fluid d-block mx-auto"/>
                              </figure>
                           </header>

                           <div className="dash-sidebar__panel-lessons flex-grow-1">
                              <div className="dash-sidebar__panel-lessons-nav-title d-flex flex-row align-items-center px-2">
                                 <span><i className="fas fa-cloud"></i></span>
                                 <span className="ml-2">{ currentLesson ? currentLesson.titolo : 'Nessuna lezione caricata' }</span>
                              </div>
                              <nav className="dash-sidebar__panel-lessons-nav">
                                 <ul>
                                    {
                                       listQuiz.map(( item ) => {
                                          return (

                                             <li key={ item.ID } onClick={ () => setSelectedQuiz( item ) }>
                                                <div className="wrap d-flex flex-row justify-content-between align-items-center px-2">
                                                   <div className="label flex-grow-1">
                                                      <span><i className="fas fa-list"></i></span>
                                                      <span className="ml-2">{ item.name }</span>
                                                   </div>
                                                </div>
                                             </li>

                                          )
                                       })
                                    }                                    
                                 </ul>
                              </nav>
                           </div>

                        </div>
                     </section>

                  </>
               :
                  null
            }
            
      </aside>
	        
	);
  
}
